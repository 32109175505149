/**
 * Styles
 */
import '../pages/fatalError/styles.scss';

/**
  * Module dependencies
  */
import React from 'react';
import { hydrateRoot } from 'nordic/hydrate';
import { loadableReady } from 'nordic/lazy';
import I18n from 'nordic/i18n';
import I18nProvider from 'nordic/i18n/I18nProvider';
import { AndesProvider } from '@andes/context';
import View from '../pages/fatalError/view';

/**
  * Get server state
  */
const {
  siteId, lowEnd, deviceType, company, translations, shopUrl, locale,
} = window.__PRELOADED_STATE__;

/**
  * i18n
  */
const i18n = new I18n({ translations });

/**
  * Mount View on client
  */
loadableReady(() => {
  hydrateRoot(
    document.getElementById('root-app'),
    <I18nProvider i18n={i18n}>
      <AndesProvider locale={locale}>
        <View
          siteId={siteId}
          lowEnd={lowEnd}
          deviceType={deviceType}
          company={company}
          shopUrl={shopUrl}
          locale={locale}
        />
      </AndesProvider>
    </I18nProvider>,
  );
});
