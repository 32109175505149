import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'nordic/page';
import { Head } from 'nordic/head';
import injectI18n from 'nordic/i18n/injectI18n';

import { ErrorScreen } from '@andes/technical-error';

import Unplugged from '@mshops-web-components/illustrations/Unplugged';
import { LOCALES_LIST } from '../../locales/localeList';

const View = ({ i18n, translations, siteId, lowEnd, deviceType, company, shopUrl, locale }) => {
  const preloadedState = {
    i18n, translations, siteId, lowEnd, deviceType, company, shopUrl, locale,
  };
  return (
    <Page
      name="fatalError"
      state={preloadedState}
      className="shop-error-page"
    >
      <Head>
        <title>{i18n.gettext('Mercado Shops')}</title>
      </Head>
      <ErrorScreen
        title={i18n.gettext('¡Ups! hubo un error')}
        description={i18n.gettext('Inténtalo de nuevo más tarde')}
        action={{
          text: i18n.gettext('Reintentar'),
          href: shopUrl,
        }}
        asset={<Unplugged />}
      />
    </Page>
  );
};

View.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  translations: PropTypes.shape({}),
  lowEnd: PropTypes.bool,
  deviceType: PropTypes.string,
  company: PropTypes.string,
  shopUrl: PropTypes.string.isRequired,
  locale: PropTypes.oneOf(LOCALES_LIST).isRequired,
};

View.defaultProps = {
  translations: {},
  lowEnd: false,
  deviceType: null,
  company: null,
};

export default injectI18n(View);
